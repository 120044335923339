import styled from 'styled-components';

import { BodySmall } from 'components/Text/BodySmall';
import { colors, cssBreakpoints } from 'theme/theme';

export const FieldDescription = styled(BodySmall)<{ $noWrap?: boolean }>`
  color: ${colors.lightContentGrey};
  line-height: 1.375;
  margin-bottom: 16px;

  ${(props) =>
    props.$noWrap
      ? `
  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: flex;
    & > div {
      flex: 1 0 auto;
      max-width: 720px;
    }
  }`
      : ''}
`;
