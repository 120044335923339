import invariant from 'invariant';
import { ReactNode, createContext, useContext } from 'react';

export type SubmitInterceptor = () => void | Promise<void>;
export type SubmittedInterceptor = () => void | Promise<void>;

export type FormErrorData<TKey> = {
  location: string;
  name: TKey | '';
  description: string;
  type?: string;
  data?: Record<string, unknown>;
};

export type FormErrorResponse<TKey> =
  | {
      responseJson?: {
        status: 'error';
        errors: FormErrorData<TKey>[];
      };
    }
  | Error;

export type FormMapErrorFunction<TValues extends Record<string, unknown>> = (
  data: FormErrorData<keyof TValues> & {
    value: TValues[keyof TValues] | undefined;
  },
) => ReactNode;

export type FormContextValue<TValues extends Record<string, unknown>> = {
  values: TValues;
  getValue: <TKey extends keyof TValues>(key: keyof TValues) => TValues[TKey];
  updateValue: <TKey extends keyof TValues>(
    name: TKey,
    value: TValues[TKey],
  ) => void;
  updateValues: (update: Partial<TValues>) => void;
  updateValueWith: <TKey extends keyof TValues>(
    name: TKey | TKey[],
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updater: (...args: Array<any>) => any,
  ) => void;
  clearValues: () => void;
  setFetchingAddress: (fetching: boolean) => void;
  errors: APIErrors;
  setErrors: (apiErrors: APIErrors, append?: boolean) => void;
  clearErrors: (errors?: string[]) => void;
  submitting: boolean;
  updateSubmitting: (submitting: boolean) => void;
  submitDisabled: boolean;
  submitForm: (values?: Partial<TValues>) => Promise<void>;
  updateSubmitDisabled: (submitDisabled: boolean) => void;
  fieldQaIdPrefix: string | null | undefined;
  edited: boolean;
  handleError: (
    error: FormErrorResponse<keyof TValues>,
    updatedFormData?: Partial<TValues> | null | undefined,
  ) => void;
  addSubmitInterceptor: (interceptor: SubmitInterceptor) => () => void;
  addSubmittedInterceptor: (interceptor: SubmittedInterceptor) => () => void;
};

export type FormSubmitFunctionData<TValues extends Record<string, unknown>> = {
  values: TValues;
  updateValues: FormContextValue<TValues>['updateValues'];
  updateValueWith: FormContextValue<TValues>['updateValueWith'];
  updateSubmitting: FormContextValue<TValues>['updateSubmitting'];
  handleError: FormContextValue<TValues>['handleError'];
  clearErrors: FormContextValue<TValues>['clearErrors'];
  clearValues: FormContextValue<TValues>['clearValues'];
  setErrors: FormContextValue<TValues>['setErrors'];
  setFetchingAddress: FormContextValue<TValues>['setFetchingAddress'];
};

export type FormSubmitFunction<
  TValues extends Record<string, unknown>,
  TReturn = void,
> = (data: FormSubmitFunctionData<TValues>) => TReturn;

export type FormRenderProps<TValues extends Record<string, unknown>> = {
  values: TValues;
  getValue: FormContextValue<TValues>['getValue'];
  updateValue: FormContextValue<TValues>['updateValue'];
  updateValueWith: FormContextValue<TValues>['updateValueWith'];
  updateValues: FormContextValue<TValues>['updateValues'];
  clearValues: FormContextValue<TValues>['clearValues'];
  hasErrors: boolean;
  setErrors: FormContextValue<TValues>['setErrors'];
  submitting: boolean;
  submitDisabled: boolean;
  updateSubmitDisabled: FormContextValue<TValues>['updateSubmitDisabled'];
  errors: FormContextValue<TValues>['errors'];
  submitForm: (values?: Partial<TValues>) => Promise<void>;
  edited: boolean;
  handleError: FormContextValue<TValues>['handleError'];
  clearErrors: FormContextValue<TValues>['clearErrors'];
  fieldQaIdPrefix: string | null | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormContext = createContext({} as FormContextValue<any>);

export function FormContextProvider<TValues extends Record<string, unknown>>({
  value,
  children,
}: {
  value: FormContextValue<TValues>;
  children?: ReactNode;
}) {
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

export function FormContextConsumer<TValues extends Record<string, unknown>>({
  children,
}: {
  children: (contextValue: FormContextValue<TValues>) => ReactNode;
}) {
  return (
    <FormContext.Consumer>
      {(value) => {
        invariant(value, 'Missing FormContext value');
        return children(value as FormContextValue<TValues>);
      }}
    </FormContext.Consumer>
  );
}

export const useFormContext = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TValues extends Record<string, unknown> = Record<string, any>,
>() => useContext(FormContext) as FormContextValue<TValues>;
